import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { Query, Paging } from '@/api/base';
import { DepartmentResource, ImportRes, PersonnelResource } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  getResourceStatusOptions,
  getStatusClass,
  getStatusI18Key,
  handleImportError,
  messageError,
  showWarningConfirm,
  translation
} from '@/utils';
import { Message } from 'element-ui';
import { ImportTemplateEnum, ResourceStatusEnum } from '@/resource/enum';
import { CascaderOption } from 'element-ui/types/cascader-panel';
import { departmentService, personnelService, positionService } from '@/api';
import AddPersonnel from './add-personnel/add-personnel.vue';
import { cloneDeep } from 'lodash';
import { ImportFile } from '@/views/dialogs/index';
import { ApiResponse } from '@/api/axios';
@Component({
  components: { OsTable, OsTableQuery, OsPagination, OsTableOperation, AddPersonnel, ImportFile }
})
export default class Personnel extends Vue {
  public tableOption: OsTableOption<PersonnelResource> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<PersonnelResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true,
      selectable: (item: object): boolean => {
        return (item as PersonnelResource).isAdmin !== 1;
      }
    },
    {
      prop: 'userCode',
      label: 'personnel.code',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    { prop: 'realName', label: 'personnel.realName', minWidth: '100px', showOverflowTooltip: true },
    {
      prop: 'userName',
      label: 'personnel.userName',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'positionNames',
      label: 'personnel.positonName',
      showOverflowTooltip: true,
      minWidth: '140px',
      formatter: (rowData: object): string => {
        return (rowData as PersonnelResource).positionDTOList?.map(x => x.posName).join('，') || '';
      }
    },
    {
      prop: 'email',
      label: 'personnel.email',
      showOverflowTooltip: true,
      minWidth: '230px'
    },
    {
      prop: 'mobile',
      label: 'personnel.mobile',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '100px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px'
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Cascader',
      field: 'departmentId',
      label: 'department.department',
      className: 'department',
      option: {
        placeholder: 'department.selectDepartment',
        filterable: true,
        clearable: true,
        showAllLevels: false,
        props: {
          checkStrictly: true,
          emitPath: false
        }
      },
      optionData: [],
      change: (value: number): void => {
        this.getPositions(value);
        this.queryForm.positionId = null;
      }
    },
    {
      type: 'Select',
      field: 'positionId',
      label: 'personnel.positonName',
      option: {
        placeholder: 'position.selectPosition'
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: getResourceStatusOptions
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'organize:user:save',
      handleClick: (): void => {
        this.openPersonnelDialog();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.import',
      operationType: 'import',
      icon: 'el-icon-upload2',
      permissionCode: 'organize:user:importData',
      handleClick: (): void => {
        this.openImportFileDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'organize:user:batchDelete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDeletePersonnel();
      }
    },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      permissionCode: 'organize:user:editStatus',
      handleClick: (): void => {
        this.batchUpdatePersonnelStatus(ResourceStatusEnum.using);
      },
      disabled: true
    },
    {
      type: 'danger',
      slot: 'end',
      plain: true,
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      permissionCode: 'organize:user:editStatus',
      handleClick: (): void => {
        this.batchUpdatePersonnelStatus(ResourceStatusEnum.disabled);
      },
      disabled: true
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<PersonnelResource> = {
    fixed: 'right',
    width: '280px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'organize:user:edit',
        handleClick: (item: PersonnelResource): void => {
          this.openPersonnelDialog(item);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'organize:user:delete',
        dynamicHidden: (item: PersonnelResource): boolean => {
          return item.isAdmin === 1;
        },
        handleClick: (item: PersonnelResource): void => {
          this.deletePersonnel(item);
        }
      },
      {
        operationType: 'resetPassword',
        type: 'text',
        label: 'login.resetPasswordTitle',
        icon: 'el-icon-refresh',
        permissionCode: 'organize:user:resetPassword',
        handleClick: (item: PersonnelResource): void => {
          this.resetPassword(item.id);
        }
      }
    ]
  };
  /**
   * 控制导入的dialog显示隐藏
   */
  public importVisible = false;
  public importTitle = 'personnel.importPersonnel';
  public editRow: PersonnelResource | null = null;
  public dialogVisible = false;
  public totalData = 0;
  /**
   * 人员导入模板的名称
   */
  public importTemplate: ImportTemplateEnum = ImportTemplateEnum.personnel;

  public queryForm: Query<{
    keywords: string;
    status: number | null;
    departmentId: number | null;
    positionId: number | null;
  }> = {
    status: null,
    departmentId: null,
    positionId: null,
    keywords: ''
  };

  public paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  private selectedRows: Array<PersonnelResource> = [];

  public created(): void {
    this.loadData();
    this.getDepartments();
    this.getPositions();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public dialogClosed(): void {
    this.editRow = null;
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.personnelTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<PersonnelResource>): void {
    this.selectedRows = selectedData;
  }

  public editSuccess(data: PersonnelResource): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public importSuccess(path: string): void {
    personnelService
      .importPersonnel(path)
      .then(() => {
        this.reloadData();
        Message.success(translation('dialog.importSuccess'));
        this.importVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  public getStatusI18Key(status: ResourceStatusEnum): string {
    return getStatusI18Key(status);
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return getStatusClass(status);
  }

  private openPersonnelDialog(data: PersonnelResource | null = null): void {
    if (data) {
      this.editRow = cloneDeep(data);
    }
    this.dialogVisible = true;
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<PersonnelResource>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'add' && x.operationType !== 'import') {
        x.disabled = value.length === 0;
      }
    });
  }

  private deletePersonnel(data: PersonnelResource): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await personnelService.delete(data.id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private async batchDeletePersonnel(): Promise<void> {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows.map(x => x.id);
          await personnelService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private loadData(): void {
    this.tableOption.loading = true;
    personnelService
      .getList(this.queryForm as PersonnelResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data || [];
        this.totalData = res.total || 0;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  /**
   * 批量修改人员状态
   * @param status 状态 启用还是禁用
   */
  private batchUpdatePersonnelStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows.map(x => x.id);
    personnelService
      .batchUpdatePersonnelStatus(idList, status)
      .then(() => {
        this.reloadData();
        Message.success(
          status === ResourceStatusEnum.using
            ? translation('common.usingSuccess')
            : translation('common.disabledSuccess')
        );
      })
      .catch(error => {
        messageError(error);
      });
  }

  private async getDepartments(): Promise<void> {
    try {
      const departments = await departmentService.getAllDepartments();
      const deptQuery = this.queryItemsOption.find(x => x.field === 'departmentId');
      deptQuery!.optionData = this.handleCascaderOption(departments);
    } catch (error) {
      messageError(error);
    }
  }

  private handleCascaderOption(departments: Array<DepartmentResource>): Array<CascaderOption> {
    return departments.map(x => {
      const option: CascaderOption = {
        label: x.depName,
        value: x.id
      };
      if (x.children && x.children.length > 0) {
        option.children = this.handleCascaderOption(x.children);
      }
      return option;
    });
  }

  private async getPositions(departmentId?: number): Promise<void> {
    try {
      const positionQuery = this.queryItemsOption.find(x => x.field === 'positionId');
      if (!positionQuery) {
        return;
      }
      const positions = departmentId
        ? await positionService.getPositionsByDepartment(departmentId)
        : await positionService.getAllPositions();
      const positionOptions = positions.map(x => {
        return { label: x.posName, value: x.id };
      });
      positionQuery!.optionData = positionOptions;
    } catch (error) {
      messageError(error);
    }
  }

  private resetPassword(id: number): void {
    showWarningConfirm(`${translation('tip.resetPassword')} 123456?`)
      .then(() => {
        personnelService
          .resetPassword(id)
          .then(() => {
            Message.success(translation('operationRes.resetSuccess'));
          })
          .catch(error => {
            messageError(error);
          });
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelReset'));
      });
  }

  private openImportFileDialog(): void {
    this.importVisible = true;
  }
}
